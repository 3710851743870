export const COLORS = {
  PURPLE: "#4255ff",
  DARK_PURPLE: "#423ed8",
  YELLOW: "#ffcd1f",
  ERROR_RED: "#ff7873",
  BLUE: "#3ccfcf",
  LIGHT_BLUE: "#59e8b5",
  APP_BACKGROUND: "#f6f7fb",
  MAIN_TEXT: "#282e3e",
  MINOR_TEXT: "#646f90",
  GRAY_TEXT: "#939bb4",
};
