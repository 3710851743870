import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { learningService } from "@api-services/learningService";
import { useNavigate, useParams } from "react-router-dom";
import { getFirstError } from "@utils/errorHandler";
import { toast } from "react-toastify";
import Confetti from "react-confetti";
import { LocalLoadingWrapper } from "@components/loading";
import Quiz from "./quiz";
import { generateQuestions } from "./generateQuestion";
import Fill from "./fill";
import { QUESTION_TYPES } from "@constants/questionTypes";
import { speak } from "@api-services/voiceService";

let timeoutId;

function Revise() {
  const [deckName, setDeckName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [terms, setTerms] = useState();
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentState, setCurrentState] = useState({
    index: 0,
    showNext: false,
  });
  let currentQuestion = null;
  let length = 0;
  if (terms) {
    currentQuestion = terms[currentState.index];
    length = terms.length;
  }

  const correctSound = new Audio(`${process.env.PUBLIC_URL}/sound/true.mp3`);
  const incorrectSound = new Audio(`${process.env.PUBLIC_URL}/sound/false.mp3`);
  const finishSound = new Audio(
    `${process.env.PUBLIC_URL}/sound/congratulation.mp3`
  );
  const handleNextQuestionClick = () => {
    if (currentState.index < length - 1) {
      setCurrentState((pre) => ({ index: pre.index + 1, showNext: false }));
    } else {
      navigate(-1);
    }
  };

  const showNext = () => {
    if (currentState.index === length - 1) {
      finishSound.play();
      setShowConfetti(true);
    }
    setCurrentState((pre) => ({ ...pre, showNext: true }));
  };

  const navigate = useNavigate();
  const { deckID } = useParams();

  const playCorrectSound = () => {
    correctSound.play();
  };
  const playIncorrectSound = () => {
    incorrectSound.play();
  };
  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    touchEndX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const swipeDistance = touchEndX.current - touchStartX.current;

    if (currentState.showNext && swipeDistance < -40) {
      handleNextQuestionClick();
    }
  };

  const speakTermWhenAnswer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    speak(currentQuestion.answer);
  };

  const handleCorrect = async () => {
    speakTermWhenAnswer();
    playCorrectSound();
    await learningService.correct(currentQuestion.progressId);
  };
  const handleIncorrect = async () => {
    speakTermWhenAnswer();
    playIncorrectSound();
    await learningService.incorrect(currentQuestion.progressId);
  };

  const speakTerm = async () => {
    speak(currentQuestion.answer);
  };

  const fetchWords = async () => {
    try {
      const res = await learningService.getReviseTerms(deckID);
      if (!res.error) {
        const { revise_terms, all_terms, deck_name } = res.data;
        if (revise_terms.length === 0) {
          toast.info("Has nothing to revise");
          navigate(`/deck/${deckID}`);
        }
        setDeckName(deck_name);
        const questions = generateQuestions(revise_terms, all_terms);
        setTerms(questions);
      } else {
        const errorMessage = getFirstError(res.error);
        toast.error(errorMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWords();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight" || event.key === "Enter") {
      handleNextQuestionClick();
    }
  };

  useEffect(() => {
    if (currentState.showNext) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentState.showNext]);

  useEffect(() => {
    if (currentQuestion) {
      const timeout = currentQuestion.type === QUESTION_TYPES.FILL ? 12000 : 5000;
      timeoutId = setTimeout(() => {
        speak(currentQuestion.answer);
      }, timeout);
    }

    // Cleanup function to clear the timeout if the component unmounts or the dependencies change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [currentQuestion]);

  return terms ? (
    <div
      className="learn-wrapper"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {showConfetti && (
        <Confetti
          gravity={0.2}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      )}
      <div className="learn-header">
        <div className="left-header"></div>
        <div className="center-header">
          <div>{deckName}</div>
          <span>{`${currentState.index + 1}/${terms.length}`}</span>
        </div>
        <div className="right-header">
          <div className="close-btn">
            <IconButton component="label" onClick={() => navigate(-1)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="learn-body">
        <div className="learn-container">
          {currentQuestion.type === QUESTION_TYPES.QUIZ ? (
            <Quiz
              question={currentQuestion}
              speakTerm={speakTerm}
              handleCorrect={handleCorrect}
              handleIncorrect={handleIncorrect}
              showNext={showNext}
              setIsLoading={setIsLoading}
            />
          ) : (
            <Fill
              question={currentQuestion}
              speakTerm={speakTerm}
              handleCorrect={handleCorrect}
              handleIncorrect={handleIncorrect}
              showNext={showNext}
              setIsLoading={setIsLoading}
            />
          )}
          <button
            className={`next-button${
              currentState.showNext ? "" : " display-none"
            }`}
            onClick={handleNextQuestionClick}
          >
            {currentState.index === length - 1 ? "Finish" : "Next Question"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <LocalLoadingWrapper open={isLoading} />
  );
}

export default Revise;
